import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CInlineDefinition,
  CHeroSlider,
  CHeroSlider03,
  CDefinition,
  CShopInfo,
  CGrandMenu,
  CShopPrivate,
  CRestaurantPlanRecommend,
  CRestaurantJumbotron,
  CBtnList,
  LContact,
  LContact02,
  LContact03,
  LStores,
  CVariableSlider,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import restaurantPlanChoice from '../../../utils/restaurantPlan-choice';
import restaurantPlanGet from '../../../utils/restaurantPlan-get';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const shopdata = shopGet('iki');

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        data={{
          logo: {
            src: '/assets/images/restaurants/iki/iki_logo.svg',
            alt: '',
            widthSp: '160px',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/iki/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/iki/kv__sp.png',
              },
            },
          ],
        }}
      ></CRestaurantJumbotron>
      <LContact02
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-iki/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
      />
      <div className="u_pt100 u_mbLarge" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CHeroSlider03
            exClass="slick-aspect-1"
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/iki/img_iki.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/img_iki__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/iki/img_iki02.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/img_iki02__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/iki/img_iki03.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/img_iki03__sp.png',
                    alt: '',
                  },
                },
              ],
              title: <>粋なホテルクオリティの味わいをご家庭でも</>,
              text: (
                <>
                  隅田川や水の波紋をイメージしてデザインされたシンプルな店内は、ホテルメイドの彩り鮮やかなスイーツが並び、香ばしいパンの香りが漂う洗練された空間。
                  <br />
                  スイーツやパン、洋風デリカや中華総菜、お弁当など、伝統の味を守りながらも新しい商品を種類豊富にご用意し、粋なおもてなしで皆様をお迎えいたします。
                </>
              ),
            }}
          />
          <CHeroSlider03
            exClass="slick-aspect-1"
            reverse={true}
            data={{
              imgList: [
                {
                  img: {
                    src: '/assets/images/restaurants/iki/img_iki04.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/img_iki04__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/iki/img_iki05.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/img_iki05__sp.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/iki/img_iki06.png',
                    alt: '',
                  },
                  imgSp: {
                    src: '/assets/images/restaurants/iki/img_iki06__sp.png',
                    alt: '',
                  },
                },
              ],
            }}
          />
          <CSectTitle
            title={{
              en: 'INFORMATION',
              ja: '店舗情報',
            }}
          />
          <p className="u_tac_sp">
            最新の営業時間は、<a href="/information/timeindex/">こちら</a>
            をご覧ください。
          </p>
          <CShopInfo data={shopdata} exClass="u_mt20" />
        </LWrap>
      </div>

      <section className="l_sect u_bgGray" style={{ overflow: 'hidden' }}>
        <LWrap>
          <CSectTitle
            title={{
              en: 'MENU',
              ja: 'メニュー',
            }}
          />
          <CRestaurantPlanRecommend
            data={restaurantPlanChoice(
              restaurantPlanGet(),
              ['スイーツ&ベーカリー 粋'],
              0
            )}
          />
          <CBtnList
            data={[
              {
                label: '他のプランを見る',
                link: {
                  href: '/restaurants/plan/',
                },
                color: 'bgWhite03',
              },
            ]}
          />
          <CGrandMenu data={shopdata} exClass="u_mt70 u_mb20" />
        </LWrap>
      </section>
      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'レストラン&バー',
              path: '/restaurants/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <LContact03
        btn={{
          btn: {
            label: '予約する',
            link: {
              href: 'https://www.tablecheck.com/ja/shops/royalparkhotel-tokyo-iki/reserve',
              blank: true,
            },
          },
        }}
        tel={{
          number: '03-5641-3600',
          note: 'レストラン予約（受付時間 9:00~19:00）',
        }}
        linkList={[
          {
            label: 'よくあるご質問',
            link: {
              href: '/faq/restaurants/',
            },
          },
          {
            label: '食物アレルギーをお持ちの方へ',
            link: {
              href: '/restaurants/allergy/',
            },
          },
        ]}
      />
      <LStores />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
